import React, { useMemo, useCallback, useContext } from 'react';
import _isEqual from 'lodash/isEqual';
import { Grid, Stack, Spacer, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Tooltip } from '@mtb/ui';
import { useTranslation } from 'react-i18next';
import { sortHelper } from '../utils/model-utils';
import { ArrowDownIcon } from '../images';
import ChevronPager from './ChevronPager';
import { toLocalizedString, toLocalizedArray } from '../utils/locales';
import { InputTypes } from './Inputs';
import { SettingsContext } from '../utils/context';
import './DeploymentModels.scss';


const MAX_ROWS_PER_PAGE = 20;

const TooltipCell = ({ row, label, cellStyle }) => {
  return (
    <TableCell
      className="ellipses"
      data-testid={`cell-${label}`}
      sx={cellStyle[label] ?? {}}>
      <Tooltip
        disableFocusListener={!row[label]}
        disableHoverListener={!row[label]}
        title={row[label] ?? ''}>
        <span>{row[label] ?? ''}</span>
      </Tooltip>
    </TableCell>
  );
};

const EnhancedTable = ({
  data,
  labels,
  tableName,
  sortableHeader = false,
  cellStyle = {},
  sortProperty,
  sortAscending,
  update,
  currentPage = 1 }) => {
  const [t] = useTranslation();
  const pageChanged = (value) => update({ page: value });

  const { pageMinIndex, pageMaxIndex } = useMemo(() => {
    return {
      pageMinIndex: (currentPage - 1) * MAX_ROWS_PER_PAGE,
      pageMaxIndex: currentPage * MAX_ROWS_PER_PAGE
    };
  }, [currentPage]);

  const sortFunc = useCallback((a, b) => {
    // If value is undefined always sort it last in list
    return a[sortProperty] === undefined ? 1 :
      b[sortProperty] === undefined ? -1 :
        sortHelper(a[sortProperty], b[sortProperty], sortAscending);
  }, [sortAscending, sortProperty]);

  const rows = useMemo(() => {
    return data.toSorted(sortFunc);
  }, [data, sortFunc]);


  const toggleSort = useCallback(property => {
    const newOrder = sortProperty === property ? !sortAscending : true;
    update({ sortProperty: property, sortAscending: newOrder });
  }, [sortProperty, sortAscending, update]);


  return <div className='table-wrapper'>
    <Box sx={{ display: 'flex' }}>
      <h4>{tableName}</h4>
      <Spacer />
      {rows?.length > MAX_ROWS_PER_PAGE &&
      <ChevronPager
        currentPage={currentPage}
        pageSize={MAX_ROWS_PER_PAGE}
        totalRows={rows.length}
        onPageChange={pageChanged} />}
    </Box>
    <TableContainer>
      <Table
        className="model-variable-table"
        size='small'>
        <TableHead>
          <TableRow hover={false}>
            {labels.map(label =>
              <TableCell
                key={`header-${label}`}
                data-testid={`header-${label}`}
                sortDirection={sortAscending ? 'asc' : 'desc'}
                sx={{ ...(cellStyle[label] ?? {}) }}>
                {sortableHeader ?
                  <TableSortLabel
                    active={sortProperty === label}
                    className='sortable-label'
                    direction={sortAscending ? 'asc' : 'desc'}
                    hideSortIcon={true}
                    Icon={ArrowDownIcon}
                    sx={{ flexDirection: 'row-reverse' }}
                    onClick={() => toggleSort(label)}>
                    {t(label)}
                  </TableSortLabel> :
                  t(label)
                }
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .filter((_, i) => i >= pageMinIndex && i < pageMaxIndex)
            .map(row =>
              <TableRow key={`row-${row.name}`}>
                {labels.map(label =>
                  <TooltipCell
                    key={`label-${row.name}-${label}`}
                    cellStyle={cellStyle}
                    label={label}
                    row={row} />
                )}
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
    {rows?.length > MAX_ROWS_PER_PAGE &&
    <ChevronPager
      currentPage={currentPage}
      pageSize={MAX_ROWS_PER_PAGE}
      right
      totalRows={rows.length}
      onPageChange={pageChanged} />}
  </div>;
};

const SchemaTable = props => {
  const {
    multiModel,
    schemas,
    sortAsc,
    sortProperty,
    currentPage,
    update } = props;
  const [t] = useTranslation();
  const settings = useContext(SettingsContext);

  const createClassesString = useCallback((list, variableType) => {
    const localizedClasses = list?.filter(i => i.toString().length)
      .map(i => variableType === InputTypes.Numeric ? toLocalizedString(i, settings.locale?.regionCode) : i);
    return toLocalizedArray(localizedClasses?.sort(), settings.locale?.regionCode);
  }, [settings.locale?.regionCode]);

  const createModelNameString = useCallback(list => {
    return toLocalizedArray(list, settings.locale?.regionCode);
  }, [settings.locale?.regionCode]);

  const response = useMemo(() => {
    const responseData = schemas[0].response;
    return ({
      ...responseData,
      classes: createClassesString(responseData.classes, responseData.dataType),
    });
  }, [schemas, createClassesString]);

  const predictors = useMemo(() => {
    const predMap = {};
    schemas.forEach(({ predictors, model }) => {
      Object.entries(predictors).forEach(([variable, { classes, type, dataType }]) => {
        if (!predMap.hasOwnProperty(variable)) {
          predMap[variable] = {
            name   : variable,
            type,
            dataType,
            classes: createClassesString(classes, dataType),
            models : []
          };
        }
        predMap[variable].models.push(model);
      });
    });

    // Last schema iteration convert models array into string
    Object.entries(predMap).forEach(([variable, { models }]) => {
      predMap[variable].models = schemas.length !== models.length ?
        createModelNameString(models) : t('all');
    });

    return Object.values(predMap);
  }, [schemas, createClassesString, createModelNameString, t]);

  return (
    <Grid
      className='schema-table'
      item
      xs>
      <Stack spacing={3}>
        {multiModel && <Box>
          <h4>{t('variables')}</h4>
          <div className='description'>{t('deploymentVariablesDescription')}</div>
        </Box>}
        <Box>
          <EnhancedTable
            cellStyle={{
              'name'    : { width: multiModel ? '15%' : '20%' },
              'type'    : { width: '15%' },
              'dataType': { width: '15%' },
              'classes' : { width: 'auto' },
            }}
            data={[response]}
            labels={['name', 'type', 'dataType', 'classes']}
            tableName={t('response')} />
        </Box>
        <Box>
          <EnhancedTable
            cellStyle={{
              'name'    : { width: multiModel ? '15%' : '20%' },
              'type'    : { width: '15%' },
              'dataType': { width: '15%' },
              'classes' : { width: multiModel ? '25%' : 'auto' },
              ...(multiModel && { 'models': { width: 'auto' } })
            }}
            currentPage={currentPage}
            data={predictors}
            labels={['name', 'type', 'dataType', 'classes', ...(multiModel ? ['models'] : [])]}
            sortableHeader={true}
            sortAscending={sortAsc}
            sortProperty={sortProperty}
            tableName={t('predictors')}
            update={update} />
        </Box>
      </Stack>
    </Grid>
  );
};

export default SchemaTable;